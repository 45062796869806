import React,{useEffect} from 'react';
import { useAppDataContext } from '../_start/layout/core/AppProvider';

const Home: React.FC = () => {
  const {setMenuSelectedKey} = useAppDataContext();
  useEffect(()=>{
    setMenuSelectedKey('1_1')
  },[])

  return (
    <h1>Home</h1>
  );
};

export default Home;