import React, { useMemo,useState } from "react";
import {
  UserOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  ImportOutlined,
  FieldTimeOutlined,
  HomeOutlined,
  ProjectOutlined,
  FundProjectionScreenOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  DashboardOutlined,
  FileTextOutlined,
  FileOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Image } from "antd";
import type { MenuTheme } from "antd";
import { Link } from "react-router-dom";
import { useAppDataContext } from "../core/AppProvider";
import "../components/custom-style.css";
import { isEmpty } from "lodash";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
const { Sider } = Layout;
type Props = {
  collapsed: any;
  setCollapsed: any;
  menuTheme?: MenuTheme;
};

const AppSider: React.FC<Props> = ({ collapsed, setCollapsed, menuTheme = "light" }) => {
  const { menuSelectedKey, permissions, isMobile ,setIsMobile } = useAppDataContext();
  const permShowPage = useMemo(() => {
    if (!permissions) return {};
    const isShowPageMap = Object.entries(permissions).reduce(
      (acc: { [key: string]: boolean }, [key, value]) => {
        acc[key] = value.isShowPage;
        return acc;
      },
      {}
    );

    return isShowPageMap;
  }, [permissions]);
  return (
    <Sider
      className="custom-sider"
      breakpoint="sm"
      collapsedWidth={isMobile ? "0" : undefined}
      onBreakpoint={broken => {
        setIsMobile(broken)
        setCollapsed(broken)
      }}
      trigger={null}
      collapsible
      collapsed={collapsed}
      theme={menuTheme}
    >
      <Image
        className='className="demo-logo-vertical"'
        src="/logo.jpg"
        preview={false}
      />
      <Menu
        mode="inline"
        selectedKeys={[menuSelectedKey]}
        items={[
          (permShowPage.dashboard || permShowPage.timesheet || permShowPage.timetracker || permShowPage.calendar) &&
          {
            key: "1",
            label: "หน้าหลัก",
            type: "group",
            children: [
              permShowPage.dashboard &&
              {
                key: "1_1",
                icon: <DashboardOutlined />,
                label: (
                  <Link to="/home" title="Dashboard">
                    Dashboard
                  </Link>
                ),
              },
              permShowPage.timesheet &&
              {
                key: "1_2",
                icon: <ClockCircleOutlined />,
                label: (
                  <Link to="/TimeSheet" title="Time Sheet">
                    บันทึกเวลา
                  </Link>
                ),
              },
              permShowPage.timetracker &&
              {
                key: "1_3",
                icon: <VideoCameraOutlined />,
                label: (
                  <Link to="/TimeTracker" title="Time Tracker">
                    ติดตามเวลา
                  </Link>
                ),
              },
              (permShowPage.calendar && !isMobile) &&
              {
                key: "1_4",
                icon: <CalendarOutlined/>,
                label: (
                  <Link to="/CalendarTime" title="Calendar">
                    ปฏิทิน
                  </Link>
                ),
              },
            ].filter((item) => !isEmpty(item) && item !== false), // Filter out falsy values
          },
          permShowPage.report &&
          {
            key: "2",
            label: "รายงาน",
            type: "group",
            children: [
              permShowPage.report &&
              {
                key: "2_1",
                icon: <FileOutlined />,
                label: (
                  <Link to="/" title="Daily Report">
                    รายวัน
                  </Link>
                ),
              },
              permShowPage.report &&
              {
                key: "2_2",
                icon: <FileTextOutlined />,
                label: (
                  <Link to="/" title="Weekly Report">
                    รายสัปดาห์
                  </Link>
                ),
              },
              permShowPage.report &&
              {
                key: "2_3",
                icon: <FundProjectionScreenOutlined />,
                label: (
                  <Link to="/" title="Monthly Report">
                    รายเดือน
                  </Link>
                ),
              },
              permShowPage.report &&
              {
                key: "2_4",
                icon: <BarChartOutlined />,
                label: (
                  <Link to="/" title="Summary Report">
                    สรุป
                  </Link>
                ),
              },
            ]
          },
          (permShowPage.project || permShowPage.employee || permShowPage.branch || permShowPage.shift || permShowPage.import || permShowPage.role) &&
          {
            key: "3",
            label: "ผู้ดูแลระบบ",
            type: "group",
            children: [
              permShowPage.project &&
              {
                key: "3_1",
                icon: <ProjectOutlined />,
                label: (
                  <Link to="/Project" title="Project">
                    โครงการ
                  </Link>
                ),
              },
              permShowPage.employee &&
              {
                key: "3_2",
                icon: <UserOutlined />,
                label: (
                  <Link to="/Employee" title="Employee">
                    พนักงาน
                  </Link>
                ),
              },
              permShowPage.branch &&
              {
                key: "3_3",
                icon: <HomeOutlined />,
                label: (
                  <Link to="/Branch" title="Branch">
                    สาขา
                  </Link>
                ),
              },
              permShowPage.shift &&
              {
                key: "3_4",
                icon: <FieldTimeOutlined />,
                label: (
                  <Link to="/Shift" title="Shift">
                    กะการทำงาน
                  </Link>
                ),
              },
              permShowPage.import &&
              {
                key: "3_5",
                icon: <ImportOutlined />,
                label: (
                  <Link to="/ImportEmp" title="Import Employee">
                    นำเข้าข้อมูลพนักงาน
                  </Link>
                ),
              },
              permShowPage.role &&
              {
                key: "3_6",
                icon: <SettingOutlined />,
                label: (
                  <Link to="/Roles" title="Role & Permission">
                    กำหนดสิทธิ์
                  </Link>
                ),
              },
            ], // Filter out falsy values
          },
        ] as any}
      />
    </Sider>
  );
};

export { AppSider };
